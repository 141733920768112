import React from "react";
import "./awards.scss";
import { graphql, StaticQuery } from "gatsby";

const Awards = ({ data }) => {
	if (!data) return null;
	const { allStrapiAwards: { nodes: collection } = {} } = data;

	return (
		<section className="full-width awards">
			<div className="grid-container">
				<div className="row">
					<div className="col-xs-12 col-md-offset-2 col-md-6 col-sm-offset-1 col-sm-10">
						<h2>Награды</h2>
					</div>
					{collection &&
						collection.map(
							({ title, description, image: { publicURL } = {}, order }) => (
								<div
									className="col-xs-12 col-md-offset-2 col-md-6 col-sm-offset-1 col-sm-10"
									key={order}
								>
									<div className="awards__award">
										<img
											alt=""
											className="awards__award__image"
											src={publicURL}
										/>
										<div className="awards__award__content">
											<h4>{title}</h4>
											<p>{description}</p>
										</div>
									</div>
								</div>
							)
						)}
				</div>
			</div>
		</section>
	);
};

export default props => (
	<StaticQuery
		query={graphql`
			query Awards {
				allStrapiAwards(sort: { fields: order }) {
					nodes {
						order
						title
						image {
							publicURL
						}
						description
					}
				}
			}
		`}
		render={data => <Awards data={data} {...props} />}
	/>
);
